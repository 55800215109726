import React, { useEffect, useState } from 'react'
import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'

interface Props {
  onResponse: (response: any) => void
}

const PersonErrorHandler = ({ onResponse }: Props) => {
  return (
    <>
      <h1>
        <span>Documento</span> em análise.
      </h1>
      <hr />
      <p>
        O teu cadastro está sendo processado. Após a conclusão da análise, tu será comunicado no teu e-mail de cadastro.
      </p>
    </>
  )
}

export default PersonErrorHandler
